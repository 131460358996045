<!--
 * @Author: wxb
 * @Date: 2021-06-22 16:14:14
 * @LastEditTime: 2021-10-27 00:14:32
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Project\proposedProInfo.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='3'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/project'}">找项目</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/proposedPro'}">拟建项目</el-breadcrumb-item>
        <el-breadcrumb-item>拟建项目详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="notice-panel"
           v-show="canSee">
        <div class="notice-title">{{proposedProInfo.projectname}} </div>
        <div class="notice-panel-info">
          <div class="notice-follow"
               :class="{followed:proposedProInfo.action==='add' }"
               title="关注项目实时接收项目最新公告"
               @click="handleFollow">
            <svg class="icon"
                 aria-hidden="true">
              <use :xlink:href="proposedProInfo.action | formatIcon"></use>
            </svg>关注项目
          </div>
        </div>
        <div class="notice-detail">
          <table class="table table-border table-bordered">
            <tr>
              <td class="text-r notice-content-title"
                  width="150">项目编号</td>
              <td class="notice-content-info">{{proposedProInfo.projectcode}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">项目阶段</td>
              <td class="notice-content-info">{{proposedProInfo.projectstage}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">发布日期</td>
              <td class="notice-content-info">{{proposedProInfo.projectrq}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">审批事项</td>
              <td class="notice-content-info">{{proposedProInfo.projectspsx}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">审批部门</td>
              <td class="notice-content-info">{{proposedProInfo.projectspbm}} </td>
            </tr>
            <tr v-show="proposedProInfo.relation_projectid">
              <td class="text-r notice-content-title">关联项目</td>
              <td class="notice-content-info">
                <!-- <el-button type="text"
                           @click="handleClickProject">{{proposedProInfo.relationproject}}</el-button> -->
                {{proposedProInfo.relationproject}}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <viporlogin bgStr="5"
                  :type="viporlogin"
                  v-show="!canSee"></viporlogin>
      <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { getProposedProjectDetails } from '@/api/project'
import dialogviporlogin from '@/components/dialogviporlogin'
import { follow } from '@/api/follow'
export default {
  name: 'proposedProInfo',
  components: {
    headerNav,
    footerNav,
    viporlogin,
    dialogviporlogin
  },
  data() {
    return {
      viporlogin: 'login',
      canSee: true,
      id: '',
      proposedProInfo: {}
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    handleClickProject() {
      const id = this.proposedProInfo.relation_projectid
      const authObj = authenticate('builtProDetail', this.memberType)
      if (authObj.flag) {
        const routeData = this.$router.resolve({
          name: 'builtProInfo',
          params: {
            id
          }
        })
        window.open(routeData.href, '_blank')
      } else {
        this.$refs.dialogviporlogin.handleShow(authObj.msg)
      }
    },
    authCheck() {
      const authObj = authenticate('proposedProDetail', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    initPage() {
      const params = {
        id: this.id
      }
      getProposedProjectDetails(params).then(res => {
        if (res.ok) {
          this.proposedProInfo = res.data
          this.proposedProInfo.action = res.action
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 项目关注
    handleFollow() {
      const params = {
        concernsId: this.id,
        concernsType: 'proposed',
        concernsTitle: this.proposedProInfo.projectname
      }
      if (this.proposedProInfo.action === 'cancle') {
        params.action = 'add'
        if (this.proposedProInfo.relation_projectid) {
          params.relationProjectId = this.proposedProInfo.relation_projectid
          params.relationProjectName = this.proposedProInfo.relationproject
        }
        this.handleFollowEvent(params)
      } else if (this.proposedProInfo.action === 'add') {
        params.action = 'cancle'
        this.$confirm('确认取消项目关注?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleFollowEvent(params)
        })
      }
    },
    handleFollowEvent(params) {
      follow(params).then(res => {
        if (res.ok) {
          this.proposedProInfo.action = params.action
          this.$forceUpdate()
          if (params.action === 'add') {
            this.$message({
              message: '关注成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: '取消关注成功',
              type: 'success'
            })
          }
        } else {
          this.$message({
            message: res,
            type: 'warning'
          })
        }
      }).catch(err => { console.log(err) })
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.id = id
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  },
  filters: {
    formatIcon(action) {
      if (action === 'add') {
        return '#icon-shoucang2'
      } else {
        return '#icon-shoucang1'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.notice {
  &-panel {
    width: 144rem;
    margin: 0 auto;
    min-height: 50rem;
    border: 1px solid #eee;
    padding: 15px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;

    &-info {
      display: flex;
      width: 800px;
      margin: 10px auto;
      justify-content: flex-end;
      font-size: 14px;

      .notice-follow {
        cursor: pointer;

        &.followed {
          .icon {
            color: #ec2929;
          }
        }
      }
    }
  }

  &-title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  &-content-title {
    background: #f4f4ff;
  }
}
</style>
